require('./src/styles/main.css')
const pageTransitionTimeout = require('./src/utils/config').pageTransitionTimeout

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), pageTransitionTimeout)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      pageTransitionTimeout
    )
  }
  return false
}
