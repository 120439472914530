import React, { Component } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import LogoImage from './logo'
import HamburgerSvg from '../images/hamburger.svg'
import CloseSvg from '../images/close.svg'
import slugify from '../utils/slugify'

const Container = styled.nav`
  height: 70px;
  display: flex;
  padding: 0 23px;
  justify-content: space-between;
  align-items: center;
  /* overflow: hidden; */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  background: rgb(245,245,245);
`

const Logo = styled(LogoImage)`
  width: 28px;
  height: 100%;
  margin-top: 3px;

  img {
    height: 100%;
  }
`

const Hamburger = styled(HamburgerSvg)`
  width: 24px;
  height: 24px;
`

const Close = styled(CloseSvg)`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 23px;
  right: 23px;
`

const HomeTitle = styled(GatsbyLink)`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
`

const HomeLogo = styled(GatsbyLink)`
  height: 55%;
`

const Drawer = styled.div`
  position: fixed;
  display: block;
  transform: ${ ({ drawerOpen }) => drawerOpen ? 'translate3d(0,0,0)' : 'translate3d(100%,0,0)' };
  transition: transform 0.5s ease-in-out;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 1;
  background: rgb(245,245,245);
  padding: 30px;
  padding-top: 75px;
  box-shadow: -3px 0px 30px 1px rgba(0, 0, 0, 0.1);
`

const NavigationItem = styled(GatsbyLink)`
  display: block;
  color: ${ ({ active }) => active ? '#6C82FF' : '#444444' };
  margin-bottom: 2.5rem;

  &:focus, &:active, &:hover {
    background: initial;
    outline: none;
    color: #444444;
  }

  &:active {
    color: #6C82FF;
  }
`

const ClickCatcher = styled.div`
  position: fixed;
  z-index: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Indent = styled.div`
  margin-left: 20px;
`

class MobileNavigation extends Component {
  constructor (props) {
    super(props)

    this.state = {
      drawerOpen: false
    }
  }

  toggleDrawer = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen
    })
  }

  render () {
    const { siteTitle, currentPathname, yachts } = this.props
    const { drawerOpen } = this.state

    return (
      <Container>
        <HomeLogo to='/'>
          <Logo/>
        </HomeLogo>
        <HomeTitle to='/'>{siteTitle}</HomeTitle>
        <Hamburger onClick={this.toggleDrawer}/>
        {drawerOpen && <ClickCatcher onTouchStart={this.toggleDrawer}/>}
        <Drawer drawerOpen={drawerOpen}>
          <Close onClick={this.toggleDrawer}/>
          <NavigationItem onClick={this.toggleDrawer} active={currentPathname.indexOf('yachts') >= 0 ? 1 : 0} to='/yachts'>Yachts</NavigationItem>
          <Indent>
            {yachts.map(({ node: { id, name } }) => (
              <NavigationItem onClick={this.toggleDrawer} key={id} to={`/yachts/${ slugify(name) }`}>
                {name}
              </NavigationItem>
            ))}
          </Indent>
          <NavigationItem onClick={this.toggleDrawer} active={currentPathname.indexOf('activities') >= 0 ? 1 : 0} to='/activities'>Activities</NavigationItem>
          <NavigationItem onClick={this.toggleDrawer} active={currentPathname.indexOf('destinations') >= 0 ? 1 : 0} to='/destinations'>Destinations</NavigationItem>
          <NavigationItem onClick={this.toggleDrawer} active={currentPathname.indexOf('videos') >= 0 ? 1 : 0} to='/videos'>Videos</NavigationItem>
          <NavigationItem onClick={this.toggleDrawer} active={currentPathname.indexOf('team') >= 0 ? 1 : 0} to='/team'>Team</NavigationItem>
        </Drawer>
      </Container>
    )
  }
}

MobileNavigation.propTypes = {
  siteTitle: PropTypes.string,
  isHomepage: PropTypes.bool
}

MobileNavigation.defaultProps = {
  siteTitle: '',
}

export default MobileNavigation
