import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import LogoImage from './logo'
import slugify from '../utils/slugify'

const Container = styled.nav`
  height: 100px;
  display: flex;
  padding: 0 40px;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgb(245,245,245);
  z-index: 1;
`

const Logo = styled(LogoImage)`
  width: 36px;
  height: 100%;
  margin-right: 25px;
  margin-top: 7px;
  transition: transform 0.4s ease-in-out;

  img {
    width: 100%;
    height: 100%;
  }

  :hover {
    transform: scale(1.03);
    transition: transform 0.4s ease-in-out;
  }

`

const Title = styled.div`
  font-weight: 600;
  letter-spacing: 0.1em;
`

const SiteTitle = styled(Link)`
  height: 50px;
  margin-right: auto;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  &:hover {
    color: initial;
  }
`

const NavigationItem = styled(Link)`
  margin-right: 10px;
  padding: 10px 20px;
  transition: background 0.3s, color 0.5s 0.5s;
  display: block;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: white;
    border-radius: 3px;
    transition: background 0.3s;
  }

  color: ${ ({ active }) => active ? '#6C82FF' : 'initial' };
`

const NavigationDropdown = styled.div`
  position: relative;
  margin-right: 10px;

  > a {
    margin-right: 0;
    transition: background 0.3s;
  }

  &:hover {
    > a {
      background: white;
      border-radius: 3px 3px 0 0;
      transition: background 0.3s;
    }

    > div {
      opacity: 1;
      visibility: visible;
      transition: visibility 0.3s ease-in, opacity 0.3s ease-in;
    }
  }

  > div {
    position: absolute;
    padding-top: 2px;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    z-index: 1;
    transition: visibility 0.3s ease-in, opacity 0.3s ease-in;

    a {
      background: white;
      margin-right: 0;
      margin-bottom: 2px;
      width: 100%;
      
      &:last-child {
        margin-bottom: 0;
        border-radius: 0 0 3px 3px;
      }
    }
  }
`

const Navigation = ({ siteTitle, currentPathname, yachts }) => (
  <Container>
    <SiteTitle to='/'>
      <Logo/>
      <Title>{siteTitle}</Title>
    </SiteTitle>
    <NavigationDropdown>
      <NavigationItem active={currentPathname.indexOf('yachts') >= 0 ? 1 : 0} to='/yachts'>Yachts</NavigationItem>
      <div>
        {yachts.map(({ node: { id, name } }) => (
          <NavigationItem key={id} to={`/yachts/${ slugify(name) }`}>
            {name}
          </NavigationItem>
        ))}
      </div>
    </NavigationDropdown>
    <NavigationItem active={currentPathname.indexOf('activities') >= 0 ? 1 : 0} to='/activities'>Activities</NavigationItem>
    <NavigationItem active={currentPathname.indexOf('destinations') >= 0 ? 1 : 0} to='/destinations'>Destinations</NavigationItem>
    <NavigationItem active={currentPathname.indexOf('videos') >= 0 ? 1 : 0} to='/videos'>Videos</NavigationItem>
    <NavigationItem active={currentPathname.indexOf('team') >= 0 ? 1 : 0} to='/team'>Team</NavigationItem>
  </Container>
)

Navigation.propTypes = {
  siteTitle: PropTypes.string,
  isHomepage: PropTypes.bool
}

Navigation.defaultProps = {
  siteTitle: '',
}

export default Navigation
