// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-region-js": () => import("/opt/build/repo/src/templates/region.js" /* webpackChunkName: "component---src-templates-region-js" */),
  "component---src-templates-yacht-js": () => import("/opt/build/repo/src/templates/yacht.js" /* webpackChunkName: "component---src-templates-yacht-js" */),
  "component---src-pages-activities-js": () => import("/opt/build/repo/src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-destinations-js": () => import("/opt/build/repo/src/pages/destinations.js" /* webpackChunkName: "component---src-pages-destinations-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-team-js": () => import("/opt/build/repo/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-videos-js": () => import("/opt/build/repo/src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-yachts-js": () => import("/opt/build/repo/src/pages/yachts.js" /* webpackChunkName: "component---src-pages-yachts-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

