import React from 'react'
import PropTypes from 'prop-types'

const Logo = ({ className }) => (
  <picture className={className}>
    <source srcSet="/logo-grey.webp" type="image/webp"/>
    <source srcSet="/logo-grey.png" type="image/jpeg"/>
    <img src="/logo-grey.png" alt="Qmon Adventures logo"/>
  </picture>
)

Logo.propTypes = {
  className: PropTypes.string,
}

export default Logo
