import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import Navigation from '../components/navigation'
import MobileNavigation from '../components/mobileNavigation'
import SEO from '../components/seo'

import Transition from '../utils/transition'
import { media } from '../utils/mediaQueries'

import config from '../../config/website'

const NavigationContainer = styled.div`
  >nav:first-child {
    display: flex;
  }

  >nav:last-child {
    display: none;
  }

  ${ media.medium`
    >nav:first-child {
      display: none;
    }

    >nav:last-child {
      display:  flex;
    }
  ` }
`

const Layout = ({ children, location }) => {
  const { allDatoCmsYacht: { edges: yachts } } = useStaticQuery(graphql`
    query SiteInfoQuery {
      allDatoCmsYacht {
        edges {
          node {
            name
            id
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO/>
      <Helmet
        title={config.siteTitle + ' | ' + getTitle(location)}
      />
      <NavigationContainer>
        <Navigation
          siteTitle={config.siteTitle}
          currentPathname={location.pathname}
          yachts={yachts}
        />
        <MobileNavigation
          siteTitle={config.siteTitle}
          currentPathname={location.pathname}
          yachts={yachts}
        />
      </NavigationContainer>
      <Transition location={location}>
        {children}
      </Transition>
    </>
  )
}

function getTitle (location) {
  const current = location.pathname

  if (current.indexOf('destinations') >= 0) {
    return 'Destinations'
  }

  switch (current) {
  case '/':
    return 'Home'
  case '/yachts':
  case '/yachts/':
    return 'Yachts'
  case '/yachts/bodhisattva':
  case '/yachts/bodhisattva/':
    return 'Bodhisattva Yacht'
  case '/yachts/mahasattva':
  case '/yachts/mahasattva/':
    return 'Mahasattva Yacht'
  case '/activities':
  case '/activities/':
    return 'Activities'
  case '/videos':
  case '/videos/':
    return 'Videos'
  case '/team':
  case '/team/':
    return 'Team'
  case '/privacy':
  case '/privacy/':
    return 'Privacy'
  default:
    break
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
